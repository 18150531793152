import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroImage from '../images/IMG_1007.jpg';

const TermsPage = () => (
  <Layout>
    <SEO title="Terms & Conditions" />
    <main>
      <div
        className="wrapper-body"
        style={{ backgroundColor: '#fff' }}
        id="why-use-e1"
      >
        <section
          id="intro"
          style={{ height: '600px', backgroundImage: `url(${HeroImage})` }}
        >
          <div className="container">
            <div
              className="col-md-12"
              style={{ paddingTop: '120px', paddingBottom: '100px' }}
            >
              <h1>Terms &amp; Conditions</h1>
            </div>
          </div>
        </section>

        <div className="padding-topbottom-50 main-text">
          <p>
            The E1Transit.com Web Site (the &quot;Site&quot;) is an online
            information service provided by E1Transit
            (&quot;E1Transit.com&quot;), subject to your compliance with the
            terms and conditions set forth below. PLEASE READ THIS DOCUMENT
            CAREFULLY BEFORE ACCESSING OR USING THE SITE. BY ACCESSING OR USING
            THE SITE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET
            FORTH BELOW. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS AND
            CONDITIONS, YOU MAY NOT ACCESS OR USE THE SITE. E1TRANSIT.COM MAY
            MODIFY THIS AGREEMENT AT ANY TIME, AND SUCH MODIFICATIONS SHALL BE
            EFFECTIVE IMMEDIATELY UPON POSTING OF THE MODIFIED AGREEMENT ON THE
            SITE. YOU AGREE TO REVIEW THE AGREEMENT PERIODICALLY TO BE AWARE OF
            SUCH MODIFICATIONS AND YOUR CONTINUED ACCESS OR USE OF THE SITE
            SHALL BE DEEMED YOUR CONCLUSIVE ACCEPTANCE OF THE MODIFIED
            AGREEMENT.
          </p>
        </div>
      </div>
    </main>
  </Layout>
);

export default TermsPage;
